// passive listener
jQuery.event.special.touchstart = {
  setup: function( _, ns, handle ){
    this.addEventListener("touchstart", handle, { passive: true });
  }
};

const heroSlides = new Swiper('.hero-slides', {
  slidesPerView: 1,
  loop: false,
  speed: 500,
  autoplay: {
    delay: 5000,
    disableOnInteraction: true,
  },
  navigation: {
    nextEl: '.image-text-button-next',
    prevEl: '.image-text-button-prev',
  },
  pagination: {
    el: '.slide-pagination',
    clickable: 'true'
  },
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
});

// const mediaVideos = new Swiper('.media-videos', {
//   slidesPerView: 1,
//   spaceBetween: 40,
//   loop: false,
//   speed: 500,
//   pagination: {
//     el: '.videos-pagination',
//     clickable: 'true'
//   },
//   navigation: {
//     nextEl: '.videos-button-next',
//     prevEl: '.videos-button-prev',
//   },
//   on: {
//     transitionStart: function(){
      
//       var videos = document.querySelectorAll('video');

//       Array.prototype.forEach.call(videos, function(video){
//         video.pause();
//       });
//     },
    
//     // auto play video when slide becomes active
//     // transitionEnd: function(){
//     //   var activeIndex = this.activeIndex;
//     //   var activeSlide = document.getElementsByClassName('video-slide')[activeIndex];
//     //   var activeSlideVideo = activeSlide.getElementsByTagName('video')[0];
//     //   activeSlideVideo.play();
//     // },
//   }
// });
// const mediaImages = new Swiper('.media-images', {
//   slidesPerView: 1,
//   loop: false,
//   speed: 500,
//   autoplay: {
//     delay: 5000,
//     disableOnInteraction: true,
//   },
//   pagination: {
//     el: '.images-pagination',
//     clickable: 'true'
//   },
//   navigation: {
//     nextEl: '.images-button-next',
//     prevEl: '.images-button-prev',
//   },
// });
// const mediaWallpaper = new Swiper('.media-wallpapers', {
//   slidesPerView: 1,
//   loop: false,
//   speed: 500,
//   autoplay: {
//     delay: 5000,
//     disableOnInteraction: true,
//   },
//   pagination: {
//     el: '.wallpapers-pagination',
//     clickable: 'true'
//   },
//   navigation: {
//     nextEl: '.wallpapers-button-next',
//     prevEl: '.wallpapers-button-prev',
//   },
// });


// rolling start!
$(document).ready(function($){
  if ('ontouchstart' in window || 'ontouch' in window) {
    $('body').addClass('touch');
  }

  // $('.video-slide video').on('click', function(){
  //   this.paused?this.play():this.pause();
  //   $(this).parent().toggleClass('playing');
  // });

  // pause the video when changing tabs
  // $('.media-content-container label').on('click', function(){
  //   $('.media-content-container .video-slide video').trigger('pause');
  //   $('.media-content-container .video-slide video').parent().removeClass('playing');
  // });
  
  // $('.video-slide video').trigger( $(this).prop('paused') ? 'play' : 'pause');
  
  // // scroll flag
  // $.fn.isInViewport = function () {
  //   let elementTop = $(this).offset().top;
  //   let elementBottom = elementTop + $(this).outerHeight();

  //   let elementBottomPercent = elementBottom * 0.15;
  
  //   let viewportTop = $(window).scrollTop();
  //   let viewportBottom = viewportTop + window.innerHeight; // <-- here
  
  //   // return elementBottom > viewportTop && elementTop < viewportBottom;

  //   return elementBottomPercent > viewportTop && elementTop < viewportBottom;
  // };

  // $(window).on('scroll', function () {
  //   if ($('.hero-banner .info-panel').isInViewport()) {
  //     $('.tbs').addClass("dropped");
  //   } else {
  //     $('.tbs').removeClass("dropped");
  //   }

  //   if ($('.footer-content .logo').isInViewport()) {
  //     $('.site-header').addClass("hide");
  //     $('.tbs').addClass("hide");
  //   } else {
  //     $('.site-header').removeClass("hide");
  //     $('.tbs').removeClass("hide");
  //   }
  // });


  // hamburger action
  $('.hamburger').on('click', function(){
    $(this).toggleClass('is-active');
    $('body').toggleClass('menu-active');
  });

  // hide menu for in-page links
  $('.inpage a').on('click', function () {
    // console.log('close');
    if ($(window).width() < 960) {
      $('.hamburger').toggleClass('is-active');
      $('body').toggleClass('menu-active');
      $('.main-navigation').toggleClass('toggled');
    };
  });

  $('.gallery').each(function() { // the containers for all your galleries
    $(this).magnificPopup({
      delegate: 'a', // the selector for gallery item
      type: 'image',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0,1] // Will preload 0 - before current, and 1 after the current image
      },
      image: {
        tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
        /*titleSrc: function(item) {
          return item.el.attr('title') + '<small>by Marsel Van Oosten</small>';
        }*/
      }
    });
  });

  $('.modal-video').magnificPopup({
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false
  });

  $('.play-trailer').magnificPopup({
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false,

    callbacks: {
      open: function() {
        // Will fire when this exact popup is opened
        // this - is Magnific Popup object
        var vid = $('.screen-one').find('video');
        if (vid.length > 0) {
          $(vid)[0].pause();
        }
      },
      close: function() {
        // Will fire when popup is closed
        var vid = $('.screen-one').find('video');
        if (vid.length > 0) {
          $(vid)[0].play();
        }
      }
    }
  });

  // Smooth scroll internal hash links
  // Select all links with hashes
  $('a[href*="#"]')
  // Remove links that don't actually link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .click(function(event) {
    // On-page links
    if (
      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
      && 
      location.hostname == this.hostname
    ) {
      // Figure out element to scroll to
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();
        $('html, body').animate({
          scrollTop: target.offset().top
        }, 1000, function() {
          // Callback after animation
          // Must change focus!
          var $target = $(target);
          $target.focus();
          if ($target.is(":focus")) { // Checking if the target was focused
            return false;
          } else {
            $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
            $target.focus(); // Set focus again
          };
        });
      }
    }
  });
});